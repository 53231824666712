import { ReactChild, useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styles from './Places.module.scss';
import styleUtils from 'shared/styles/util.module.scss';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import {
  KeyValueObject,
  Map,
  PlacePrice,
  PlacePriceSubscription,
  PromiseStatus,
  QueryStorage,
  SeasonTicketSubscription,
} from 'entities/Venue/model';
import { Button, Loader, Modal, ModalBody } from 'shared/ui';
import MapComponent from 'features/Map/ui/MapComponent/MapComponent';
import MapFooter, {
  GoToCartButton,
  PlaceCard,
  PlaceCardMobile,
} from 'entities/Venue/ui/Places/SelectedPlaces';
import {classNames, Money, pluralize, useBehaviorSubject, useWindowSize} from 'shared/utils';
import { PreferenceKeys, PreferencesService } from 'processes/Preferences';
import {
  isError,
  PackedReservationError,
  PlacesReservationBaseErrors,
  ReservationError,
} from 'entities/Venue/utils/types';
import i18next from 'i18next';
import { AuthService } from 'processes/Auth';
import { OrderService } from 'entities/Order';
import { OrderContext } from 'entities/Order/model/OrderContext';
import { Path } from 'routes/path';
import { usePlacePhotoConfig, usePlacesStatusSubscription } from 'entities/Venue/hooks';
import { defineSubscriptionConfig } from 'entities/Venue/utils/defineSubscriptionConfig';

export interface MapLoader {
  getPlaces: () => Promise<PlacePrice[]>;
  getMap: () => Promise<Map>;
  subscribeToEventPlacesStatus?: (
    subscriptionConfig: PlacePriceSubscription
  ) => ZenObservable.Subscription;
  subscribeToSeasonTicketPlacesStatus?: (
    subscriptionConfig: SeasonTicketSubscription
  ) => ZenObservable.Subscription;
}

function PlacesReservation(props: {
  authService: AuthService;
  orderService: OrderService;
  isBannerOnTop?: boolean;
  bannerHeight?: number;
  mapLoader: MapLoader;
  errorModalContentResolver: (
    error: PlacesReservationBaseErrors
  ) => ReactChild | ReactChild[] | Element | undefined;
  preferencesService: PreferencesService;
}): JSX.Element {
  const { mapLoader, preferencesService } = props;
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const { t } = useTranslation();
  const { width, height } = useWindowSize();
  const [map, setMap] = useState<Map>();
  const [places, setPlaces] = useState<PlacePrice[]>([]);
  const [loaded, setLoaded] = useState<boolean>(false);
  const [error, setError] = useState<PlacesReservationBaseErrors>();
  const [localBannerHeight, setLocalBannerHeight] = useState<number | undefined>(
    props.bannerHeight
  );
  const {
    reservedPlaces,
    updateReservedPlaces,
    reservePlace,
    placesCountByType,
    orderItemsIds,
    removePlacesFromOrder,
    eventType,
    goToCart,
    updateOrderItemsIds,
    isAuthenticated,
  } = useContext(OrderContext);
  const [localReservedPlaces, setLocalReservedPlaces] = useState<PlacePrice[]>(
    reservedPlaces.current || []
  );
  const [updatedPlace, setUpdatedPlace] = useState<PlacePrice>();
  const localCountByType = useRef<number>(placesCountByType?.current ?? 0);
  const pendingPlaces = useRef<PlacePrice[]>([]);
  const placesIdByIndex = useRef<KeyValueObject>({});
  const placesQueryRef = useRef<QueryStorage<void>>({ query: [], status: 'none' });
  const authenticated = useBehaviorSubject(props.authService.authenticated);
  const placePhotoConfig = usePlacePhotoConfig(preferencesService);
  const isMobile = width <= 975;
  const maxTicketsInOrder = +preferencesService.get(PreferenceKeys.MAX_TICKETS_IN_ORDER) || 5;

  const updatePlaces = (pp: PlacePrice) => {
    setPlaces(prev =>
      prev.map(item => {
        if (item.place.id === pp.place.id) {
          item = pp;
        }
        return item;
      })
    );
  };

  const refMapContainer = useRef<HTMLDivElement>(null);

  const updateStatus = (status: PromiseStatus) => {
    if (placesQueryRef.current.status !== status) {
      placesQueryRef.current.status = status;
    }
  };
  const updatePlacesQueryRef = (newQuery: Promise<void>, newStatus?: PromiseStatus) => {
    placesQueryRef.current = {
      query: newQuery,
      status: newStatus || placesQueryRef.current.status,
    };
  };

  const handlePlacePriceChange = (pp: PlacePrice) => {
    setUpdatedPlace(pp);
    console.log(
      `Статус места, ${pp.place.number}, в ряду ${pp.place.rowNumber}, сектора "${pp.place.sectorTitle}" изменился на ${pp.status}`
    ); // TODO: delete when subscription completed
  };

  const handleError = (err: any) => {
    console.error(err);
    setError(err);
  };

  const {
    subscriptionType: sType,
    subscriptionConfig: sConfig,
  } = defineSubscriptionConfig(eventType?.current, id, { handlePlacePriceChange, handleError });
  const placeStatusChanges = usePlacesStatusSubscription(sType, sConfig, mapLoader);

  useEffect(() => {
    const placesSubscription = placeStatusChanges();
    setLoaded(false);

    return () => {
      placesSubscription.unsubscribe();
    };
  }, []);

  const loadActualPlaceStatuses = useCallback(async () => {
    if (placesQueryRef.current.status === ('none' || 'rejected')) {
      updateStatus('pending');
      const newPlacesQuery = mapLoader
        .getPlaces()
        .then(
          value => {
            setPlaces([...value]);
            updateStatus('fulfilled');
            value.forEach((item, index) => {
              placesIdByIndex.current[item.place.id] = index;
            });
          },
          () => updateStatus('rejected')
        )
        .finally(() => {
          updateStatus('none');
          setLoaded(true);
        });
      updatePlacesQueryRef(newPlacesQuery);
    }
  }, [mapLoader]);

  const redirectFunc = () => {
    history.push(Path.CART);
  };

  const onSelect = async (place: PlacePrice) => {
    if (localCountByType.current < maxTicketsInOrder) {
      setLocalReservedPlaces(localReservedPlaces => [...localReservedPlaces, place]);
      localCountByType.current++;
      pendingPlaces.current.push(place);
      let reserveResult;
      if (reservePlace?.current !== undefined) {
        reserveResult =
          eventType &&
          (await reservePlace
            .current({
              place,
              orderService: props.orderService,
              id,
              eventType: eventType.current,
            })
            .finally(
              async() => {
                pendingPlaces.current = pendingPlaces.current.filter(
                  item => item.place.id !== place.place.id
                );
                // HOTFIX(OP-1307): Цена в заказе может отличаться от цены в PlacePrice, поэтому находим
                // актуальную цену места в заказе и подменяем в данных, для "всплывающих" карточек
                const currentOrder= await props.orderService.getCurrentOnly();
                const placeWithActualPrice = {
                  ...place,
                  price: currentOrder?.items.find(oi => oi.item.place.id === place.place.id)?.price || place.price,
                }
                setLocalReservedPlaces(localReservedPlaces => {
                  const filteredPlaces = localReservedPlaces.filter(pl => pl.place.id !== placeWithActualPrice.place.id);
                  return [...filteredPlaces, placeWithActualPrice]
                });
              }
            )
          );
      }
      if (isError(reserveResult)) {
        setError(reserveResult as PlacesReservationBaseErrors);
        setLocalReservedPlaces(localReservedPlaces =>
          localReservedPlaces.filter(item => item !== place)
        );
        localCountByType.current--;
      } else {
        if (updateOrderItemsIds && orderItemsIds && reserveResult) {
          updateOrderItemsIds({
            ...orderItemsIds.current,
            [place.place.id]: {
              orderItemId: reserveResult.orderItemId,
              orderId: reserveResult.order.id,
            },
          });
        }
      }
    } else {
      setError({ errorType: 'CartAlreadyFull' } as PlacesReservationBaseErrors);
    }
  };

  const onRemove = async (places: PlacePrice[]) => {
    places.forEach(place => {
      setLocalReservedPlaces(localReservedPlaces => [
        ...localReservedPlaces.filter(item => item.place.id !== place.place.id),
      ]);
      pendingPlaces.current.push(place);
    });
    let removalResult;
    if (removePlacesFromOrder?.current) {
      removalResult =
        orderItemsIds &&
        (await removePlacesFromOrder.current({
          places,
          orderItemsIds: orderItemsIds?.current,
          orderService: props.orderService,
        }));
    }
    places.forEach(place => {
      pendingPlaces.current = pendingPlaces.current.filter(
        item => item.place.id !== place.place.id
      );
    });
    localCountByType.current = localCountByType.current - places.length;
    if (isError(removalResult)) {
      setError(removalResult as PlacesReservationBaseErrors);
      setLocalReservedPlaces(localReservedPlaces => [...localReservedPlaces, ...places]);
      localCountByType.current = localCountByType.current + localReservedPlaces.length;
    }
  };

  useEffect(() => {
    (async () => {
      try {
        const map = await mapLoader.getMap();
        // console.log('[XYZ] getMap, deps: ',id, loadActualPlaceStatuses, mapLoader);
        setMap(map);
        await loadActualPlaceStatuses();
      } catch (e) {
        console.log(e);
      }
    })();
  }, []);

  useEffect(() => {
    if (pendingPlaces.current.length === 0) {
      setLocalReservedPlaces(reservedPlaces.current);
      if (placesCountByType) {
        localCountByType.current = placesCountByType?.current;
      }
    }
  }, [reservedPlaces.current]);

  useEffect(() => {
    if (updatedPlace !== undefined) {
      updatePlaces(updatedPlace);
    }
  }, [updatedPlace]);

  useEffect(() => {
    setLocalBannerHeight(props.bannerHeight);
  }, [props.bannerHeight]);

  const newHeight = useMemo(() => {
    return localBannerHeight && height - localBannerHeight - 12;
  }, [localBannerHeight, height]);

  useEffect(() => {
    setLocalReservedPlaces([]);
  }, [authenticated]);

  return !loaded ? (
    <Loader />
  ) : (
    <div ref={refMapContainer} className={styles.mapContainer}>
      <MapComponent
        placesList={places}
        placesIdByIndex={placesIdByIndex.current}
        containerId="map"
        mapSize={map?.size || { height: 0, width: 0 }}
        mapUrl={map?.src || ''}
        maxZoom={Number(preferencesService.get(PreferenceKeys.MAP_MAX_ZOOM))}
        placesRadius={Number(preferencesService.get(PreferenceKeys.MAP_PLACE_RADIUS))}
        placesWeight={Number(preferencesService.get(PreferenceKeys.MAP_PLACE_WEIGHT))}
        onSelect={onSelect}
        onRemove={it => onRemove([it])}
        selectedPlaces={localReservedPlaces}
        placePhotoConfig={placePhotoConfig}
        styleHeight={width <= 760 && localBannerHeight && newHeight ? { height: newHeight } : {}}
      />
      {localReservedPlaces.length > 0 ? (
        <MapFooter>
          {isMobile ? (
            <PlaceCardMobile
              placesNumber={localReservedPlaces.length}
              placesPrice={localReservedPlaces
                .map(item => item.price)
                .reduce((a, b) => a.plus(b), Money.ZERO)}
              onRemove={() => onRemove(localReservedPlaces)}
            />
          ) : (
            localReservedPlaces.map((it, index) => (
              <PlaceCard
                key={it.place.id}
                place={it}
                zIndex={index}
                onRemove={it => onRemove([it])}
              />
            ))
          )}
          <GoToCartButton
            onClick={async () => {
              goToCart?.current &&
                updateReservedPlaces &&
                goToCart.current({
                  authService: props.authService,
                  orderService: props.orderService,
                  id,
                  reservedPlaces: localReservedPlaces,
                  updateReservedPlaces,
                  redirectFunc,
                  eventType: eventType?.current,
                });
            }}
            title={
              isAuthenticated?.current
                ? `${t('event.match_places.instant_reservation_strategy.cart_button_title')}`
                : `${t('common.add_to_cart')}`
            }
          />
        </MapFooter>
      ) : null}
      <Modal
        visible={error !== undefined}
        closeModal={() => setError(undefined)}
        outsideClickClose={true}
      >
        <>
          {error &&
            getErrorModalBody(
              props.errorModalContentResolver(error) || defaultErrorsModalContentResolver(error, preferencesService),
              () => setError(undefined)
            )}
        </>
      </Modal>
    </div>
  );
}

function getErrorModalBody(body: ReactChild | ReactChild[] | Element, onClose: () => void) {
  return (
    <ModalBody>
      <div style={{ maxWidth: 300 }}>
        <header className={styleUtils.center}>
          <h2>{i18next.t('common.attention')}</h2>
        </header>
        <div className={styleUtils.dispencedList} style={{ maxWidth: 350 }}>
          <div className={classNames(styleUtils.centeredText, styleUtils.center)}>{body}</div>
          <div className={styleUtils.center}>
            <Button id="getErrorModalBodyOk" onClick={onClose}>
              {i18next.t('common.ok') ?? 'Понятно'}
            </Button>
          </div>
        </div>
      </div>
    </ModalBody>
  );
}

function defaultErrorsModalContentResolver(error: PlacesReservationBaseErrors, preferencesService?: PreferencesService) {
  switch (error.errorType) {
    case 'ReservationError': {
      const e = error as ReservationError;
      return (
        <span className={classNames(styleUtils.centeredText)}>
          {i18next.t('places_reservation.reservation_error.0')},{' '}
          <span style={{ fontWeight: 'bold' }}>
            {i18next.t('common.places.0')} {e?.placePrice.place.number}{' '}
            {i18next.t('common.places.1')} {e?.placePrice.place.rowNumber}{' '}
            {i18next.t('common.places.2')} {e?.placePrice.place.sectorTitle}{' '}
          </span>{' '}
          {i18next.t('places_reservation.reservation_error.1')}
        </span>
      );
    }
    case 'PackedReservationError': {
      const e = error as PackedReservationError;
      return (
        <span className={classNames(styleUtils.centeredText)}>
          {i18next.t('places_reservation.packed_reservation_error.0')}:{' \n '}
          {e.places.map(place => {
            return (
              <span style={{ fontWeight: 'bold' }}>
                {i18next.t('common.places.0')} {place.place.number} {i18next.t('common.places.1')}{' '}
                {place.place.rowNumber} {i18next.t('common.places.2')} {place.place.sectorTitle}{' '}
              </span>
            );
          })}
          {i18next.t('places_reservation.packed_reservation_error.1')}
        </span>
      );
    }
    case 'CancelError': {
      return (
        <span className={classNames(styleUtils.centeredText)}>
          {i18next.t('places_reservation.cancel_error')}
        </span>
      );
    }
    case 'AuthorizationRequired': {
      return (
        <span className={classNames(styleUtils.centeredText)}>
          {i18next.t('places_reservation.authorization_required')}
        </span>
      );
    }
    case 'CartAlreadyFull': {
      const maxTicketsInOrder = preferencesService ? +preferencesService.get(PreferenceKeys.MAX_TICKETS_IN_ORDER) : 5;
      const words: [string, string, string] = [ `${i18next.t('places_reservation.cart_already_full.words.0')}`, `${i18next.t('places_reservation.cart_already_full.words.1')}`, `${i18next.t('places_reservation.cart_already_full.words.2')}` ];
      return (
        <span className={classNames(styleUtils.centeredText)}>
          {i18next.t('places_reservation.cart_already_full.part_1')} {maxTicketsInOrder} { pluralize(maxTicketsInOrder, words) } {i18next.t('places_reservation.cart_already_full.part_2')}
        </span>
      );
    }

    default: {
      return (
        <span className={classNames(styleUtils.centeredText)}>
          {i18next.t('places_reservation.default')}
        </span>
      );
    }
  }
}

export default PlacesReservation;
