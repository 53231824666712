import { classNames } from 'shared/utils';
import styles from './Logo.module.scss';

function Logo(props: { src?: string; classes?: string; width?: number; height?: number }) {
  return (
    <div className={styles.logoContainer}>
      <div
        className={classNames(styles.logoImage, props.classes)}
        style={{
          width: props.width,
          height: props.height,
          backgroundImage: props.src ? `url(${props.src})` : 'var(--clubLogo)',
        }}
      />
    </div>
  );
}

export default Logo;

Logo.defaultProps = {
  width: '51px',
  height: '42px',
};
