import { useTranslation } from 'react-i18next';
import React, { useEffect, useState } from 'react';
import styles from './CouponsCard.module.scss';
import { DiscountType, Promocode } from 'entities/Order/model/coupon.types';
import { getErrorMessage, Money } from 'shared/utils';
import { Loader } from 'shared/ui';

const UserCouponsCard = (props: {
  title: string;
  appliedPromocode?: string;
  getCoupons: () => Promise<Promocode[]>;
  applyPromocode: (value: string) => void;
  cancelPromocode: () => void;
}) => {
  const { t } = useTranslation();
  const [coupons, setCoupons] = useState<Promocode[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [aplliedPromo, setAplliedPromo] = useState(!!props.appliedPromocode);

  const getCouponCode = (coupon: string) => {
    return coupon.slice(-5);
  };

  const handleApplyCoupon = async (value: string) => {
    try {
      setLoading(true);
      if (!aplliedPromo) {
        setAplliedPromo(true);
        await props.applyPromocode(value);
      } else {
        if (!error) await props.cancelPromocode();
      }
    } catch (err: any) {
      setError(getErrorMessage(err.message));
      console.error(err);
    } finally {
      setLoading(false);
    }
  };

  const handleClick = async (code: string) => {
    if (aplliedPromo && props.appliedPromocode === code) {
      await props.cancelPromocode();
    } else if (!aplliedPromo) {
      await handleApplyCoupon(code);
    } else {
      return;
    }
  };

  const refreshCoupons = async () => {
    setLoading(true);
    const fetchedCoupons = await props.getCoupons();
    setCoupons(fetchedCoupons);
    setLoading(false);
  };

  const renderDiscountValue = (value: string, type: DiscountType) => {
    if (type === DiscountType.PERCENT) {
      return (
        <>
          {Number(value).toFixed()}%
        </>
      );
    } else {
      return (
        <>
          {Money.parse(value).toLocaleString()}
        </>
      );
    }
  };

  useEffect(() => {
    ( async () => await refreshCoupons() )();
    setAplliedPromo(!!props.appliedPromocode);
  }, [props.appliedPromocode]);

  return (
    <>
      {aplliedPromo}
      {coupons && coupons.length > 0 &&
        <ul className={styles.coupons}>
          {props.title}
          {coupons.map((coupon, i) => (
            <li key={getCouponCode(coupon.value)} className={styles.coupon}>
              <div className={styles.discount}>
                <h3 className={styles.value}>
                  {renderDiscountValue(coupon.descriptor.discount.value, coupon.descriptor.discount.type)}
                </h3>
                <div className={styles.date}>
                  {
                    coupon.descriptor.period.start &&
                    <span>
                      {t('coupons_card.date.from')} {coupon.descriptor.period.start?.toLocaleDateString()}
                    </span>
                  }
                  {
                    coupon.descriptor.period.end &&
                    <span>
                      {t('coupons_card.date.to')} {coupon.descriptor.period.end?.toLocaleDateString()}
                    </span>
                  }
                </div>
              </div>
              <p className={styles.label}>Код: <span className={styles.code}>{getCouponCode(coupon.value)}</span></p>
              <span className={styles.divider} />
              {
                <button
                  className={styles.textButton}
                  disabled={( aplliedPromo && props.appliedPromocode !== getCouponCode(coupon.value) ) || loading}
                  onClick={() => handleClick(getCouponCode(coupon.value))}
                >
                  {
                    loading ?
                      <Loader className={styles.loader} />
                      : aplliedPromo && props.appliedPromocode === getCouponCode(coupon.value)
                        ? t('coupons_card.cancel')
                        : t('coupons_card.apply')
                  }
                </button>
              }
            </li>
          ))}
        </ul>
      }
    </>
  );
};

export default UserCouponsCard;
