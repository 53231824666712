import { gql } from '@apollo/client';

export const ORDER_FRAGMENT_FIELDS = gql`
  fragment OrderFields on Order {
    id
    appliedPromocode
    price
    priceWithDiscount
    additionalData {
      loyaltyAmount
    }
    items {
      id
      type
      title
      price
      priceWithDiscount
      loyaltyUsed
      reservedUntil
      item{
        ... on SeasonTicket {
          id
          descriptor {
            id
            title
            seasons {
              id
              endDate
            }
          }
          place {
            id
            number
            coordinates {
              x
              y
            }
            row {
              number
              sector {
                title
              }
            }
          }
          price
        }
        ... on Ticket {
          visibleId
          place {
            id
            number
            coordinates {
              x
              y
            }
            row {
              number
              sector {
                title
              }
            }
          }
          price
          event {
            id
            startTime
            team1 {
              title
            }
            team2 {
              title
            }
            stage {
              tournament {
                logo {
                  publicLink
                }
              }
            }
          }
        }
      }
    }
  }
`;

export const PROMOCODE_DESCRIPTOR_FRAGMENT = gql `
  fragment PromocodeDescriptorFragment on PromocodeDescriptor {
    id
    title
    type
    period {
      start
      end
    }
    commentary
    discount {
      type
      value
    }
    maxUseCountsPerUser
    tournamentBound {
      season {
        id
        startDate
        endDate
      }
      tournaments {
        title
      }
    }
    stageBound {
      season {
        id
        startDate
        endDate
      }
      stages {
        title
      }
    }
    seasonBound {
      seasons {
        id
        startDate
        endDate
      }
    }
    sectorBound {
      sectorIds
    }
    rowBound {
      rowIds
    }
    priceBound {
      priceRange {
        start
        end
      }
    }
    orderItemTypeBound {
      types
    }
    orderItemBound {
      items {
        type
        descriptorIds
        items {
          __typename
          ... on Tickets_RemoteMatch {
            id
            title
          }
          ... on SeasonTicketDescriptor {
            id
            title
          }
        }
      }
    }
    codes {
      total
    }
  }
`;
