import { ObjectDeserializer } from '@sebbia/object-deserializer';
import { coordinatesDeserializer, moneyMapper } from 'shared/model';
import { getDateTimeParserWithTimezone } from 'shared/utils';
import * as OrdersType from './types';
import { PlaceStatus } from 'entities/Venue';
import {
  PromocodePaginatedResult,
  Discount,
  DiscountType,
  NamedEntity,
  OrderItemConnection,
  OrderItemTypeBound,
  Period,
  PriceBound,
  Promocode,
  PromocodeDescriptor,
  PromocodeType,
  Season,
  SectorBound,
  StageBound,
  Stages,
  TournamentBound,
  Tournaments, PromocodeWithPartialDescriptor,
} from './coupon.types';
import { ItemType } from './types';

function remoteFileDeserializer(o: ObjectDeserializer): OrdersType.RemoteFile {
  return {
    publicLink: o.required('publicLink').asString,
  };
}

function ticketsTournamentDeserializer(o: ObjectDeserializer): OrdersType.Tickets_RemoteTournament {
  return {
    logo: o.optional('logo')?.asObject(remoteFileDeserializer),
  };
}

function ticketsStageDeserializer(o: ObjectDeserializer): OrdersType.Tickets_RemoteStage {
  return {
    tournament: o.required('tournament')?.asObject(ticketsTournamentDeserializer),
  };
}

function ticketsTeamDeserializer(o: ObjectDeserializer): OrdersType.Tickets_RemoteTeam {
  return {
    title: o.required('title').asString,
  };
}

function ticketsEventDeserializer(o: ObjectDeserializer): OrdersType.Tickets_RemoteMatch {
  //TODO: Parse venue first, extract it`s timezone and set in parser
  const timezone = undefined;
  const datetimeParser = getDateTimeParserWithTimezone(timezone);
  return {
    id: o.required('id').asString,
    startTime: o.required('startTime')?.as(datetimeParser),
    team1: o.required('team1').asObject(ticketsTeamDeserializer),
    team2: o.required('team2').asObject(ticketsTeamDeserializer),
    stage: o.required('stage')?.asObject(ticketsStageDeserializer),
  };
}

function ticketSectorPlaceDeserializer(o: ObjectDeserializer): OrdersType.Tickets_RemoteSector {
  return {
    title: o.required('title').asString,
  };
}

function ticketRowPlaceDeserializer(o: ObjectDeserializer): OrdersType.Tickets_RemoteRow {
  return {
    number: o.required('number').asString,
    sector: o.required('sector').asObject(ticketSectorPlaceDeserializer),
  };
}

function ticketPlaceDeserializer(o: ObjectDeserializer): OrdersType.Tickets_RemotePlace {
  return {
    id: o.required('id').asString,
    number: o.required('number').asString,
    row: o.required('row').asObject(ticketRowPlaceDeserializer),
    coordinates: o.required('coordinates').asObject(coordinatesDeserializer),
  };
}

function ticketDeserializer(o: ObjectDeserializer): OrdersType.Ticket {
  return {
    visibleId: o.required('visibleId').asString,
    place: o.required('place').asObject(ticketPlaceDeserializer),
    event: o.required('event').asObject(ticketsEventDeserializer),
    color: '#000000', // o.required('place.priceCategory.color').asString, //TODO: поставила дефолтные значения, т.к. в какой-то момент запрос стал возвращать null (по непонятной приине), на отрисовку не влияет
    price: o.required('price').as(moneyMapper),
    priceCategoryId: '1',//o.required('place.priceCategory.id').asString,
    status: PlaceStatus.BLOCKED,
  };
}

function ticketsRemoteSeasonDeserializer(o: ObjectDeserializer): OrdersType.Tickets_RemoteSeason {
  return {
    id: o.required('id').asString,
    endDate: o.required('endDate').asString,
  };
}

function seasonTicketDescriptorDeserializer(o: ObjectDeserializer): OrdersType.SeasonTicketDescriptor {
  return {
    id: o.required('id').asString,
    title: o.required('title').asString,
    seasons: o.optional('seasons')?.asArrayOfObjects(ticketsRemoteSeasonDeserializer),
  };
}

function seasonTicketDeserializer(o: ObjectDeserializer): OrdersType.SeasonTicket {
  return {
    place: o.required('place').asObject(ticketPlaceDeserializer),
    descriptor: o.required('descriptor').asObject(seasonTicketDescriptorDeserializer),
    color: '#000000',// o.required('place.priceCategory.color').asString,
    status: PlaceStatus.BLOCKED,
    priceCategoryId: '1',//o.required('place.priceCategory.id').asString,
    price: o.required('price').as(moneyMapper),
  };
}

function orderItemDeserializer(o: ObjectDeserializer, type: OrdersType.ItemType): OrdersType.OrderItem {
  switch (type) {
    case OrdersType.ItemType.TICKET:
      return o.required('item').asObject(ticketDeserializer);
    case OrdersType.ItemType.SEASON_TICKET:
      return o.required('item').asObject(seasonTicketDeserializer);
    case OrdersType.ItemType.PROLONG_SEASON_TICKET:
      return o.required('item').asObject(seasonTicketDeserializer);
    case OrdersType.ItemType.OUTFIT:
      return o.required('item').asObject(ticketDeserializer);

    default:
      return o.required('item').asObject(ticketDeserializer);
  }
}

function itemsDeserializer(o: ObjectDeserializer): OrdersType.OrderItemDescriptor {
  return {
    id: o.required('id').asString,
    type: o.required('type').asEnum(OrdersType.ItemType),
    title: o.required('title').asString,
    price: o.required('price').as(moneyMapper),
    priceWithDiscount: o.required('priceWithDiscount').as(moneyMapper),
    loyaltyUsed: o.optional('loyaltyUsed')?.asString,
    reservedUntil: o.optional('reservedUntil')?.asString,
    item: orderItemDeserializer(o, o.required('type').asEnum(OrdersType.ItemType)),
  };
}

export function additionalDataDeserializer(o: ObjectDeserializer): OrdersType.OrderAdditionalData {
  return {
    loyaltyAmount: o.optional('loyaltyAmount')?.as(moneyMapper),
  };
}

export function orderDeserializer(o: ObjectDeserializer): OrdersType.Order {
  return {
    id: o.required('id').asString,
    additionalData: o.optional('additionalData')?.asObject(additionalDataDeserializer),
    price: o.required('price').as(moneyMapper),
    priceWithDiscount: o.required('priceWithDiscount').as(moneyMapper),
    appliedPromocode: o.optional('appliedPromocode')?.asString,
    items: o.required('items').asArrayOfObjects(itemsDeserializer),
  };
}

// COUPONS

export const promocodeDeserializer = (o: ObjectDeserializer): Promocode => {
  return {
    value: o.required('value').asString,
    useCounter: o.required('useCounter').asNumber,
    descriptor: o.required('descriptor').asObject((d) => promocodeDescriptorDeserializer(d)),
  };
};

export const promocodeDescriptorDeserializer = (o: ObjectDeserializer): PromocodeDescriptor => {
  return {
    id: o.required('id').asString,
    title: o.required('title').asString,
    type: o.required('type').asEnum(PromocodeType),
    period: o.required('period').asObject((p) => periodDeserializer(p)),
    maxUseCountsPerUser: o.optional('maxUseCountsPerUser')?.asNumber,
    tournamentBound: o.optional('tournamentBound')?.asObject((b) => tournamentBoundDeserializer(b)),
    stageBound: o.optional('stageBound')?.asObject((b) => stageBoundDeserializer(b)),
    seasonBound: o.optional('seasonBound.seasons')?.asArrayOfObjects((b) => seasonDeserializer(b)),
    sectorBound: o.optional('sectorBound')?.asObject((b) => sectorBoundDeserializer(b)),
    priceBound: o.optional('priceBound.priceRange')?.asObject((b) => priceBoundDeserializer(b)),
    orderItemTypeBound: o.optional('orderItemTypeBound')?.asObject((t) => orderItemTypeBoundDeserializer(t)),
    orderItemBound: o.optional('orderItemBound.items')?.asArrayOfObjects((i) => orderItemBoundDeserializer(i)),
    discount: o.required('discount').asObject((d) => discountDeserializer(d)),
    codes: o.required('codes').asObject((c) => codesDeserializer(c)),
  };
};

export const partialDescriptorDeserializer = (o: ObjectDeserializer): Partial<PromocodeDescriptor> => {
  return {
    id: o.optional('id')?.asString,
    title: o.optional('title')?.asString,
    type: o.optional('type')?.asEnum(PromocodeType),
    period: o.optional('period')?.asObject((p) => periodDeserializer(p)),
    maxUseCountsPerUser: o.optional('maxUseCountsPerUser')?.asNumber,
    tournamentBound: o.optional('tournamentBound')?.asObject((b) => tournamentBoundDeserializer(b)),
    stageBound: o.optional('stageBound')?.asObject((b) => stageBoundDeserializer(b)),
    seasonBound: o.optional('seasonBound.seasons')?.asArrayOfObjects((b) => seasonDeserializer(b)),
    sectorBound: o.optional('sectorBound')?.asObject((b) => sectorBoundDeserializer(b)),
    priceBound: o.optional('priceBound.priceRange')?.asObject((b) => priceBoundDeserializer(b)),
    orderItemTypeBound: o.optional('orderItemTypeBound')?.asObject((t) => orderItemTypeBoundDeserializer(t)),
    orderItemBound: o.optional('orderItemBound.items')?.asArrayOfObjects((i) => orderItemBoundDeserializer(i)),
    discount: o.optional('discount')?.asObject((d) => discountDeserializer(d)),
    codes: o.optional('codes')?.asObject((c) => codesDeserializer(c)),
  };
};

export const periodDeserializer = (p: ObjectDeserializer): Period => {
  return {
    start: p.optional('start')?.asDate,
    end: p.optional('end')?.asDate,
  };
};

export const tournamentBoundDeserializer = (b: ObjectDeserializer): TournamentBound => {
  return {
    season: b.required('season').asObject((s) => seasonDeserializer(s)),
    tournaments: b.required('tournaments').asArrayOfObjects((t) => tournamentsDeserializer(t)),
  };
};

export const stageBoundDeserializer = (b: ObjectDeserializer): StageBound => {
  return {
    season: b.required('season').asObject((s) => seasonDeserializer(s)),
    stages: b.required('stages').asArrayOfObjects((s) => stagesDeserializer(s)),
  };
};

export const stagesDeserializer = (s: ObjectDeserializer): Stages => {
  return {
    title: s.required('title').asString,
  };
};

export const tournamentsDeserializer = (t: ObjectDeserializer): Tournaments => {
  return {
    title: t.required('title').asString,
  };
};

export const seasonDeserializer = (s: ObjectDeserializer): Season => {
  return {
    id: s.required('id').asString,
    startDate: s.required('startDate').asDate,
    endDate: s.required('endDate').asDate,
  };
};

export const sectorBoundDeserializer = (b: ObjectDeserializer): SectorBound => {
  return {
    sectorIds: b.required('sectorIds').asArray((s) => s),
  };
};

export const priceBoundDeserializer = (b: ObjectDeserializer): PriceBound => {
  return {
    start: b.optional('start')?.asString,
    end: b.optional('end')?.asString,
  };
};

export const itemsBoundDeserializer = (i: ObjectDeserializer): NamedEntity => {
  return {
    id: i.required('id').asString,
    title: i.required('title').asString,
  };
};

export const orderItemTypeBoundDeserializer = (t: ObjectDeserializer): OrderItemTypeBound => {
  return {
    types: t.required('types').asArray((o) => o as ItemType),
  };
};

export const orderItemBoundDeserializer = (i: ObjectDeserializer): OrderItemConnection => {
  return {
    type: i.required('type')?.asEnum(ItemType),
    descriptorIds: i.required('descriptorIds').asArray((o) => o),
    items: i.required('items').asArrayOfObjects((i) => itemsBoundDeserializer(i)),
  };
};

export const discountDeserializer = (d: ObjectDeserializer): Discount => {
  return {
    type: d.required('type').asEnum(DiscountType),
    value: d.required('value').asString,
  };
};

export const codesListDeserializer = (o: ObjectDeserializer): PromocodeWithPartialDescriptor => {
  return {
    value: o.required('value').asString,
    useCounter: o.required('useCounter').asNumber,
    descriptor: o.required('descriptor').asObject((d) => partialDescriptorDeserializer(d)),
  };
};

export const codesDeserializer = (c: ObjectDeserializer): PromocodePaginatedResult => {
  return {
    total: c.required('total').asNumber,
    // list: c.required('list').asArrayOfObjects((o) => codesListDeserializer(o)),
  };
};
