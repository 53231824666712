import { OrderItem, SeasonTicket, Ticket } from 'entities/Order';

export function isNonNullable<T>(v: T): v is NonNullable<T> {
  return v !== undefined && v !== null;
}

export function isKeyOfObject<T extends Object>(obj: T, key: any): key is keyof typeof obj {
  return obj.hasOwnProperty(key);
}

export function isArray<T>(obj: any): obj is T[] {
  return Array.isArray(obj);
}

export function isNotNull<T>(value?: T): value is Exclude<T, null> {
  return value !== null && value !== undefined;
}

export function isTicket(f: OrderItem): f is Ticket {
  return (f as Ticket).event !== undefined;
}

export function isSeasonsTicket(f: OrderItem): f is SeasonTicket {
  return (f as SeasonTicket).descriptor !== undefined;
}
