import { ItemType } from "./types"

export type Promocode = {
  value: string;
  useCounter: number;
  descriptor: PromocodeDescriptor;
};

export type PromocodeWithPartialDescriptor = Omit<Promocode, "descriptor"> & {
  descriptor: Partial<PromocodeDescriptor>;
}

export enum PromocodeType {
  PROMOCODE = 'PROMOCODE',
  PERSONAL_COUPON = 'PERSONAL_COUPON',
}

export type Period = {
  start?: Date;
  end?: Date;
};

export type Season = {
  id: string;
  startDate: Date;
  endDate: Date;
};

export type TournamentBound = {
  season: Season;
  tournaments: Tournaments[];
};

export type Tournaments = {
  title: string;
};

export type Stages = {
  title: string;
};

export type StageBound = {
  season: Season;
  stages: Stages[];
};

export type SectorBound = {
  sectorIds: string[];
};

export type PriceBound = {
  start?: string;
  end?: string;
};

export type OrderItemTypeBound = {
  types: ItemType[];
};

export type NamedEntity = {
  id: string;
  title: string;
};

export type OrderItemConnection = {
  type: ItemType;
  descriptorIds: string[];
  items: NamedEntity[];
};

export enum DiscountType {
  ABSOLUTE = 'ABSOLUTE',
  PERCENT = 'PERCENT',
  FIXED_PRICE = 'FIXED_PRICE',
}

export type Discount = {
  type: DiscountType;
  value: string;
};

export type PromocodePaginatedResult = {
  total: number;
  // list: PromocodeWithPartialDescriptor[];
};

export type PromocodeDescriptor = {
  id: string;
  title: string;
  type: PromocodeType;
  period: Period;
  maxUseCountsPerUser?: number;
  tournamentBound?: TournamentBound;
  stageBound?: StageBound;
  seasonBound?: Season[];
  sectorBound?: SectorBound;
  priceBound?: PriceBound;
  orderItemTypeBound?: OrderItemTypeBound;
  orderItemBound?: OrderItemConnection[];
  discount: Discount;
  codes: PromocodePaginatedResult;
};
